<template>
	<b-card>
		<b-row>
			<b-col cols="12" md="8">
				<b-tabs v-model="activeTabIndex" @input="tabChange">
					<b-tab :title="$t('Executed')"></b-tab>
					<b-tab :title="$t('Non-Execution')"></b-tab>
				</b-tabs>
			</b-col>
			<b-col
				cols="12"
				md="4"
				class="d-flex align-items-center justify-content-start justify-content-md-end"
			>
				<b-button variant="primary" @click="newAction">
					<span class="text-nowrap">{{$t('Add Switch Schedule')}}</span>
				</b-button>
				<b-button class="ml-1" variant="success" @click="syncAction">
					<span class="text-nowrap">{{$t('Sync to device')}}</span>
				</b-button>
			</b-col>
		</b-row>
		
		<b-table
			ref="deviceList"
			hover
			:items="dataList"
			:fields="tableColumns"
			primary-key="id"
			show-empty
			:empty-text="$t('No Data')"
			stacked="sm"
			class="mt-2"
		>
			<!-- Column: content -->
			<template #cell(content)="data">
				{{data.item.cmd==1 ? '开机' : '关机'}}
			</template>
			<!-- Column: range -->
			<template #cell(range)="data">
				{{data.item.begin}} 至 {{data.item.end}}
			</template>
			<!-- Column: cycle -->
			<template #cell(cycle)="data">
				{{ formatCycle(data.item.cycle).map(item=>item.name).join(',')}} 
			</template>
			<!-- Column: Actions -->
			<template #cell(actions)="data">
				<!-- 编辑 -->
				<feather-icon
					:id="`${data.item.id}-edit-icon`"
					icon="EditIcon"
					class="cursor-pointer"
					size="16"
					@click="editAction(data.item)"
				/>
				<b-tooltip
					:title="$t('Edit')"
					class="cursor-pointer"
					:target="`${data.item.id}-edit-icon`"
				/>

				<!-- delete -->
				<feather-icon
					:id="`${data.item.id}-delete-icon`"
					icon="Trash2Icon"
					class="cursor-pointer ml-1"
					size="16"
					@click="deleteAction(data.item)"
				/>
				<b-tooltip
					:title="$t('Delete')"
					class="cursor-pointer"
					:target="`${data.item.id}-delete-icon`"
				/>
			</template>
		</b-table>
		<!-- Pagination -->
		<Pagination
			:currentPage="currentPage"
			:pageSize="pageSize"
			:count="dataList.length"
			class="mt-2"
			v-if="dataList.length>0"
		></Pagination>
		<SwitchScheduleNew
			:is-active.sync="isNewSidebarActive"
			v-if="isNewSidebarActive"
			:activeData="activeData"
			:deviceId="deviceId"
			@refetch-data="getData(1)"
		/>
		<Function>
			<ul class="colors-list list-unstyled mb-0">
				<li>
					<feather-icon
						size="15"
						icon="ChevronsRightIcon"
					/>
					<span>{{$t('Function Declaration')}}1</span>
				</li>
				<li>
					<feather-icon
						size="15"
						icon="ChevronsRightIcon"
					/>
					<span>{{$t('Function Declaration')}}2</span>
				</li>
			</ul>
		</Function>
	</b-card>
</template>

<script>
import { BButton,BRow, BCol, BCard, BTabs, BTab, BTable, BTooltip, BBadge } from 'bootstrap-vue'
import Pagination from "@/components/public/Pagination.vue"
import SwitchScheduleNew from '@/components/device/SwitchScheduleNew.vue'
import {getTask, deleteTask, syncTask} from '@/libs/apis/serverApi'
import {formatCycle, secondsToTimeObj} from "@/libs/utils"

export default {
	components: {
		BButton,
		BRow,
		BCol,
		BCard,
		BTabs,
		BTab,BTable, BTooltip, BBadge,
		Pagination,
		SwitchScheduleNew
	},
	data () {
		return {
			deviceId: this.$route.query.id,
			tableColumns: [
				{ key: 'id', },
				{ key: 'content', label: this.$t('Content') },
				{ key: 'range', label: this.$t('Date Range') },
				{ key: 'cycle', label: this.$t('Cycle Setting') },
				{ key: 'time', label: this.$t('Time') },
				{ key: 'actions',label: this.$t('Actions') },
			],
			loading: false,
			dataList: [],
			pageSize: 10,
			currentPage: 1,
			activeData: null,
			activeTabIndex: 0,
			isNewSidebarActive: false
		}
	},
	created(){
		if(!this.deviceId){
			this.$route.back()
			return
		}
		this.getData(1)
	},
	methods: {
		formatCycle,
		getData(page) {
			this.loading = true
			page = page || 1;
			getTask({
				deviceid: this.deviceId,
				type: this.activeTabIndex,
				offset: (page - 1) * this.pageSize,
				limit: this.pageSize,
			}).then((res) => {
				this.loading = false
				this.dataList = res.rows.map(item=>{
					let timeObj = secondsToTimeObj(item.ts)
					item.time = `${timeObj.HH}:${timeObj.mm}`
					return item
				})
				this.currentPage = page;
			}, err=>{
				this.loading = false
				this.$toast.error(err.message)
			});
		},
		tabChange (e) {
			this.getData(1)
		},
		newAction () {
			this.activeData = null
			this.isNewSidebarActive=true
		},
		editAction(item){
			this.activeData = item
			this.isNewSidebarActive = true
		},
		syncAction () {
			syncTask({deviceid: this.deviceId}).then(res=>{
				this.$toast(this.$t('Sync Success'))
			},err=>{
				this.$toast.error(err.message)
			})
		},
		deleteAction(item){
			this.$bvModal
				.msgBoxConfirm(this.$t('deleteDataTip'), {
					title: this.$t('Tips'),//'Please Confirm',
					size: 'sm',
					okVariant: 'danger',
					okTitle: this.$t('Delete'),
					cancelTitle: this.$t('Cancel'),
					cancelVariant: 'outline-secondary',
					hideHeaderClose: false,
					centered: true,
				})
				.then(isConfirm => {
					if(isConfirm){
						deleteTask ({id: item.id}).then(res=>{
							this.$toast(this.$t('Delete Success'))
							this.getData(this.currentPage)
						},err=>{
							this.$toast.error(err.message)
						})
					}
				})
		}
	}
}
</script>

<style>

</style>
