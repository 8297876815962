<template>
	<b-sidebar
		:visible="isActive"
		bg-variant="white"
		sidebar-class="sidebar-lg"
		shadow
		backdrop
		no-header
		right
		@hidden="resetForm"
		@change="(val) => $emit('update:is-active', val)"
	>
		<template #default="{ hide }">
			<!-- Header -->
			<div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
				<h5 class="mb-0">
					{{activeData ? $t('Edit Switch Schedule') :$t('Add Switch Schedule')}}
				</h5>

				<feather-icon
					class="ml-1 cursor-pointer"
					icon="XIcon"
					size="16"
					@click="hide"
				/>

			</div>

			<!-- BODY -->
			<validation-observer
				#default="{ handleSubmit }"
				ref="refFormObserver"
			>
				<!-- Form -->
				<b-form
					class="p-2"
					@submit.prevent="handleSubmit(onSubmit)"
					@reset.prevent="resetForm"
				>
					<b-form-group label="ID" v-if="activeData">
						{{activeData.id}}
					</b-form-group>
					<!-- content -->
					<validation-provider
						#default="validationContext"
						:name="$t('Content')"
						rules="required"
					>
						<b-form-group
							:label="$t('Content')"
							label-for="content"
						>
							<v-select
								id="content"
								v-model="formData.cmd"
								:class="{'is-invalid': !!validationContext.errors[0]}"
								:searchable="false"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								:options="contentOptions"
								:reduce="option=>option.id"
								:placeholder="$t('Please Select')"
							>
							</v-select>

							<b-form-invalid-feedback>
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>
					
					<!-- Begin -->
					<validation-provider
						#default="validationContext"
						:name="$t('Begin Date')"
						rules="required"
					>
						<b-form-group
							:label="$t('Begin Date')"
							label-for="begin"
						>
							<b-form-datepicker
								id="begin"
								v-model="formData.begin"
								:state="getValidationState(validationContext)"
								:max="formData.end"
								:min="today"
								v-bind="langs[calendarLocale] || {}"
								:locale="calendarLocale"
							/>

							<b-form-invalid-feedback>
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>

					<!-- End -->
					<validation-provider
						#default="validationContext"
						:name="$t('End Date')"
						rules="required"
					>
						<b-form-group
							:label="$t('End Date')"
							label-for="end"
						>
							<b-form-datepicker
								id="end"
								v-model="formData.end"
								:state="getValidationState(validationContext)"
								:min="formData.begin"
								v-bind="langs[calendarLocale] || {}"
								:locale="calendarLocale"
							/>

							<b-form-invalid-feedback>
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>
					
					<!-- Cycle Setting -->
					<validation-provider
						#default="validationContext"
						:name="$t('Cycle Setting')"
						rules="required"
					>
						<b-form-group
							:label="$t('Cycle Setting')"
							label-for="cycleSet"
						>
							<b-form-checkbox-group
								id="cycleSet"
								v-model="formData.cycle"
								:state="getValidationState(validationContext)"
								:options="cycleOptions"
								value-field="id"
								text-field="name"
							/>
							

							<b-form-invalid-feedback style="display:block !important">
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>


					<!-- Time -->
					<validation-provider
						#default="validationContext"
						:name="$t('Time')"
						rules="required"
					>
						<b-form-group
							:label="$t('Time')"
							label-for="time"
						>
							<b-form-timepicker
								id="time"
								v-model="formData.time"
								:state="getValidationState(validationContext)"
								v-bind="timerLangs[calendarLocale] || {}"
								:locale="calendarLocale"
							/>
							

							<b-form-invalid-feedback>
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>


					<!-- Form Actions -->
					<div class="d-flex mt-2">
						<b-button
							v-ripple.400="'rgba(255, 255, 255, 0.15)'"
							variant="primary"
							class="mr-2"
							type="submit"
						>
							{{ activeData ? $t('Save') : $t('Add')}}
						</b-button>
						<b-button
							v-ripple.400="'rgba(186, 191, 199, 0.15)'"
							type="button"
							variant="outline-secondary"
							@click="hide"
						>
							{{$t('Cancel')}}
						</b-button>
					</div>

				</b-form>
			</validation-observer>
		</template>
	</b-sidebar>
</template>

<script>
import {mapState} from 'vuex'
import {
	BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,BFormDatepicker, BFormTimepicker, BFormCheckboxGroup
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver} from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import {formatDate,secondsToTimeObj,formatCycle} from '@/libs/utils'
import {createTask,saveTask} from '@/libs/apis/serverApi'
import config from "@/libs/config"

export default {
	components: {
		BSidebar,
		BForm,
		BFormGroup,
		BFormInput,
		BFormInvalidFeedback,
		BButton,
		BFormDatepicker,
		BFormTimepicker,
		BFormCheckboxGroup,

		vSelect,

		// Form Validation
		ValidationProvider,
		ValidationObserver,
	},
	directives: {
		Ripple,
	},
	model: {
		prop: 'isActive',
		event: 'update:is-active',
	},
	props: {
		isActive: {
			type: Boolean,
			required: true,
		},
		deviceId: {
			type: String,
			required: true,
		},
		activeData: {
			type: Object,
			default () {
				return null
			}
		}
	},
	computed: {
		...mapState({
			locale: state=>state.app.locale
		}),
		calendarLocale () {
			if(this.locale=='zh_CN'){
				return 'zh'
			}
			return this.locale
		}
	},
	data() {
		return {
			required,
			today: formatDate(new Date(),'YYYY-MM-DD'),
			langs: {
				'zh': {
					labelPrevDecade: '过去十年',
					labelPrevYear: '上一年',
					labelPrevMonth: '上个月',
					labelCurrentMonth: '当前月份',
					labelNextMonth: '下个月',
					labelNextYear: '明年',
					labelNextDecade: '下一个十年',
					labelToday: '今天',
					labelSelected: '选定日期',
					labelNoDateSelected: '未选择日期',
					labelCalendar: '日历',
					labelNav: '日历导航',
					labelHelp: '使用光标键浏览日期',
					labelHours: '小时',
				},
			},
			timerLangs:{
				zh: {
					labelMinutes: '分钟',
					labelSeconds: '秒',
					labelAmpm: '上午下午',
					labelAm: '上午',
					labelPm: '下午',
					labelIncrement: '增量',
					labelDecrement: '减量',
					labelSelected: '选定时间',
					labelNoTimeSelected: '没有选择时间',
					labelCloseButton: '关',
				}
			}
		}
	},
	created () {
	},
	setup(props, { emit }) {
		let blankFormData = {
			cmd: '',
			begin: '',
			end: '',
			time: '',
			cycle: []
		}
		let activeData = props.activeData
		if(activeData){
			const timeObj = secondsToTimeObj(activeData.ts)
			blankFormData = {
				id: activeData.id,
				cmd: activeData.cmd,
				begin: activeData.begin,
				end: activeData.end,
				cycle: formatCycle(activeData.cycle).map(item=>item.id),
				time: timeObj.HH+':'+timeObj.mm+':00'
			}
		}

		const formData = ref(JSON.parse(JSON.stringify(blankFormData)))
		const resetformData = () => {
			formData.value = JSON.parse(JSON.stringify(blankFormData))
		}

		const {
			refFormObserver,
			getValidationState,
			resetForm,
		} = formValidation(resetformData)

		const contentOptions = [{
			id: 1,
			label: '开机'
		},{
			id: 2,
			label: '关机'
		}]
		const cycleOptions = config.cycleList

		return {
			formData,

			refFormObserver,
			getValidationState,
			resetForm,

			contentOptions,
			cycleOptions
		}
	},
	methods: {
		getCycle(cycles){
			let temp = [0,0,0,0,0,0,0,0]
			cycles.forEach(item=>{
				temp[item] = 1
			})
			let str = temp.join('')
			return parseInt(str,2)
		},
		getTs (time) {
			let timeArr = time.split(':').map(item => +item)
			let seconds = timeArr[0]*3600 + timeArr[1]*60 + timeArr[2]
			return seconds
		},
		createData () {
			const cycle = this.getCycle(this.formData.cycle)
			const ts = this.getTs(this.formData.time)
			let params = {
				deviceid: this.deviceId,
				cmd: this.formData.cmd,
				begin: this.formData.begin,
				end: this.formData.end,
				ts,
				cycle
			}
			createTask(params).then(rex=>{
				this.$emit('refetch-data')
				this.$emit('update:is-active', false)
			})
		},
		saveData () {
			const cycle = this.getCycle(this.formData.cycle)
			const ts = this.getTs(this.formData.time)
			let params = {
				deviceid: this.deviceId,
				id: this.formData.id,
				cmd: this.formData.cmd,
				begin: this.formData.begin,
				end: this.formData.end,
				ts,
				cycle
			}
			saveTask(params).then(rex=>{
				this.$emit('refetch-data')
				this.$emit('update:is-active', false)
			})
		},
		onSubmit () {
			if(this.activeData){
				this.saveData()
			}else {
				this.createData()
			}
		}
	}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
